define("discourse/plugins/hosted-site/services/upgrade", ["exports", "@ember/service", "discourse/helpers/page-reloader", "discourse/lib/ajax", "discourse/lib/later", "discourse/lib/text", "discourse/plugins/hosted-site/discourse/components/modal/admin-upgrade-plan"], function (_exports, _service, _pageReloader, _ajax, _later, _text, _adminUpgradePlan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UpgradeService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    async delayedReload() {
      (0, _later.default)(() => {
        (0, _pageReloader.reload)();
      }, 2000);
    }
    async modalForPlan(planName, context) {
      try {
        const result = await (0, _ajax.ajax)(`/admin/upgrade/${planName}`);
        if (result.success && result.upgrade_details) {
          const cooked = await (0, _text.cook)(result.upgrade_details.message);
          this.modal.show(_adminUpgradePlan.default, {
            model: {
              plan_id: result.upgrade_details.plan_id,
              add_on_ids: result.upgrade_details.add_on_ids,
              suggested_upgrade_message: cooked,
              onComplete: this.delayedReload,
              currentTier: result.upgrade_details.current_tier,
              suggestedTier: result.upgrade_details.new_tier,
              suggestedTierDisplayName: result.upgrade_details.new_tier_display_name,
              additionalCost: result.upgrade_details.additional_cost,
              trialStartDate: result.upgrade_details.trial_start_date,
              trialDurationDays: result.upgrade_details.trial_duration_days,
              newTierPluginCount: result.upgrade_details.new_tier_plugin_count,
              newTierPluginExamples: result.upgrade_details.new_tier_plugin_examples,
              newTierPageviewLimit: result.upgrade_details.new_tier_pageview_limit,
              newTierStorageLimit: result.upgrade_details.new_tier_storage_limit,
              newTierStaffUserLimit: result.upgrade_details.new_tier_staff_user_limit,
              newTierEmailLimit: result.upgrade_details.new_tier_email_limit,
              context
            }
          });
        } else {
          return this.dialog.alert(result.message || "There was an error submitting your upgrade request. Please try again later or send an email to team@discourse.org so we can look into it.");
        }
      } catch {
        return this.dialog.alert("There was an error submitting your upgrade request. Please try again later or send an email to team@discourse.org so we can look into it.");
      }
    }
  }
  _exports.default = UpgradeService;
});